.main{
  background-color: rgb(0, 0, 0);
  height: 550px;
  width: 95vw;
  /* display: flex; */
  color: white;
  margin: 37.5px;
}
main{
  background-color: #282c34;
}
.main>h1{
  color: white;
  /* position: relative; */
  position: absolute;
  left: 60px;
}

.main>h1>span{
  color: orange;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* height: 100vh;
  width: 100vw; */
  width: 100vw;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  /*font-size: calc(10px + 2vmin);
  color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




@media screen and (max-width:768px){
  .main{
    height: 1000px;
    width: 90vw;
  }
}
@media screen and (max-width:500px) {
  .App-header{
    background-color: black;
  }
}