.comm{
    display: flex;
}

.unication{
    display: flex;
    flex: 1;
    width: 50vw;
    position: absolute;
    right: 100px;
    flex-direction: column;
    margin-top: 130px;
}
.unication>h2{
    color: orange;
}


@media screen and (max-width:768px) {
    .unication{
        width: 70vw;
        margin-top: 600px;
    }
}