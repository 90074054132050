.bio{
    display: flex;
}
.graphy{
    display: flex;
    flex: 1;
    width: 50vw;
    position: absolute;
    right: 100px;
    flex-direction: column;
    margin-top: 160px;
}
.graphy>p{
    font-weight: bold;
    font-size: 20px;
    display: flex;
    gap: 2rem;
    margin-top: -5px;
}

.graphy>p>:nth-of-type(1){
    color: rgb(80, 78, 78);
}


@media screen and (max-width:768px) {
    .graphy{
        margin-top: 600px;
        right: 110px;
        width: 73vw;
    }
}

@media screen and (max-width:600px) {
    .graphy{
        margin-top: 630px;
        width: 75vw;
        right: 50px;
    }
}

@media screen and (max-width:460px) {
    .graphy{
        right: 60px;
        width: 82vw;
    }
}
