form{
    position: relative;
    /* right: 130px; */
    top: 40px;
    margin-left: 61vw;
}
input:nth-of-type(1){
    background-color: rgb(61, 57, 57);
    border: none;
    color: white;
    height: 23px;
    /* border-radius: 20px; */
    /* position: absolute;
    right: 130px;
    top: 60px; */
}
input::placeholder{
    color: rgb(212, 209, 209);
}
input:nth-of-type(2){
    background-color: rgb(61, 57, 57);
    border: none;
    color: white;
    height: 25px;
    cursor: pointer;
}
.ul>li{
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 3vw;
    /* border-bottom: 4px solid rgb(43, 41, 41); */
    /* font-weight: bold; */
    font-weight: 700;
    /* font-size: 15px; */
}
li{
    border-bottom: 4px solid rgb(43, 41, 41);

}
.ul{
    display: flex;
    /* justify-content: center;
    align-items: center; */
    /* margin-left: 36vw; */
    margin-top: 90px;
    margin-bottom: 20px;
    position: absolute;
    left: 41vw;
}
.link{
    text-decoration: none;
    color: white;
    text-transform: uppercase;
}


@media screen and (max-width:900px) {
    .ul{
        position: absolute;
        left: 32vw;
        gap: 1vw;
    }
    .ul>li{
        font-weight: 500;
    }
}



@media screen and (max-width:768px) {
    .ul{
        margin-top: 540px;
        left: 10vw;
    }
    form{
        margin-left: 42vw;
    }
}

@media screen and (max-width:600px) {
    .ul>li{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        margin-top: -10px;
        gap: 1.5rem;
    }
    .ul{
        left: 20vw;
    }
    form{
        margin-left: 34vw;
        top: 70px;
    }
}


@media screen and (max-width:460px) {
    form{
        margin-left: 23vw;
        top: 76px;
    }
}

@media screen and (max-width:460px) {
    .ul{
        left: 11vw;
    }
}


@media screen and (max-width:394px) {
    form{
        margin-left: 8vw;
    }
}

@media screen and (max-width:350px) {
    .ul{
        left: 1vw;
    }
}

@media screen and (max-width:330px) {
    form{
        margin-left: 2vw;
    }
}