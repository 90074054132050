.app{
    display: flex;
}

.rence{
    display: flex;
    flex: 1;
    width: 50vw;
    position: absolute;
    right: 100px;
    flex-direction: column;
    margin-top: 150px;
}

.rence>p{
    margin-top: -5px;
    display: flex;
    justify-content: space-between;
}
.rence>p>:nth-of-type(1){
    text-transform: uppercase;
}

.rence>p>:nth-of-type(2){
    background-color: rgb(201, 45, 45);
    padding: 3px 8px 2px 8px;
    border-radius: 10px;
}

@media screen and (max-width:768px) {
    .rence{
        margin-top: 630px;
        right: 80px;
        width: 75vw;
        /* background-color: blue; */
    }
    .rence>p>:nth-of-type(2){
        /* justify-content: space-between;
        gap: 5rem; */
        gap: 10rem;
    }
}


@media screen and (max-width:460px) {
    .rence{
        right: 50px;
        width: 80vw;
    }
}